.section-title{
    font-size:24px;
    font-weight:bold;
    color:blueviolet;
}
.section-title p {
    font-size: 16px;
    font-weight: bold;
    color:black;
}
.side-content h4 {
    font-weight: bold;
    align-items: center;
    text-align:center;
}
