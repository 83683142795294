.software-title {
    text-align: center;
}

.software-title h1 {
        font-size: 20px;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 8px 20px;
        margin: 0;
        background: #e7f1fd;
        color: #106eea;
        display: inline-block;
        text-transform: uppercase;
        border-radius: 50px;
}
.software-bg {
    background-color: #f6f9fe;
}

.image-container{
    position:relative;
    max-width:100%;
}
.image-container img {
        width: 100%;
        height: 75vh;
        background-size: cover;
        position: relative;
        border-radius:20px;
}
.image-container img:before {
        content: "";
        background: rgba(255, 255, 255, 0.3);
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
}
.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(130, 130, 130, 0.7);
    padding: 20px;
    color: #fff;
    width: 80%;
    border-radius: 30px;
}
.overlay-text h1{
    font-size:40px;
    font-weight:bold;
    color:wheat;
}
.overlay-text p{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size:24px;
    font-weight:700;
    color:black;
}






















 


